body {
  height: 100vh;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  line-height: 1.4;
  
}

a {
  text-decoration: none;
}

a:hover { text-decoration: underline; }

#root {

  max-width: 700px;
  margin: 30px;

}

.introContainer {

  padding: 15px;
  margin: 10px 0;
  border: 1px solid #EEE;

  min-width: 200px;
  

}

label {
  line-height: 2;
  font-size: 0.8rem;
  color: black;

  opacity: 0.9;

  width: 100%;
}

label input {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;

  border-radius: 3px;
  font-size: 1.3rem;

  border: 2px solid #EEE;
  outline: none;

  margin-bottom: 15px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.btn {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: black;

  padding: 10px;
  border-radius: 3px;

  text-decoration: none;
  outline: none;
  border: none;

  cursor: pointer;
  font-size: 1rem;
}

.btn.darkblue {
  color: white;
  background-color: #1B2845;
}

.btn.blue {
  color: white;
  background-color: #A663CC;
}

.btn.green {
  color: black;
  background-color: #F4B860;
}

.btn.sml {
  font-size: 0.8rem;
  padding: 8px;
  margin-top: 0;
}

.send {

  display: flex;
  flex-direction: row;

  justify-content: space-between;

  margin: 15px 0;
  
}

.send input {
  flex-grow: 2;
  margin-right: 5px;

  box-sizing: border-box;

  border: 2px solid #EEE;
  padding: 10px;
  font-size: 1rem;
}

.send button {
  margin-left: 5px;
}

div.message {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0;

  padding: 10px 5px;
  border-radius: 3px;
}

div.message b, div.message span.text {
  margin: 0 10px;
}

div.title {
  display: inline-block;
  padding: 5px;
  background-color: black;
  color: white;
  margin-top: 5px;
}

h1.welcome {
  text-align: center;
}

div .sml {
  font-size: 0.8rem;
  opacity: 0.5;
}

span.decrypt-error {
  font-family: monospace;
  color: red;
}

.message img {
  border-radius: 50%;
  height: 2rem;
}

div.newChatWidget {
  padding: 10px;
  border: 2px dashed #EEE;
  border-radius: 3px;
}

header {
  display: flex;
  align-items: center;
  justify-content: center;
}

header img {
  max-width: 100%;
  max-height: 50px;
  display: block;
}

footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

footer p {
  opacity: 0.5;
  font-size: 0.8rem;
  user-select: none;
}

.newChatWidget p {
  font-size: 0.8rem;
  margin-top: 10px;
  margin-bottom: 0;

  opacity: 0.7;
}

.helpThanksForReading {
  padding: 15px;
  border: 2px dashed #EEE;
}